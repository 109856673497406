<template>
	<div>
	<el-select
			v-model="gameId"
			filterable
			placeholder="请选择比赛"
		>
		<el-option
			v-for="item in gameList"
			:key="item.gameid"
			:label="item.gamename"
			:value="item.gameid"
		/>
	</el-select>
	<el-table
		:data="tableData"
		stripe
		style="width: 50%;top:40px;margin:auto">
		<el-table-column
			prop="dept"
			label="选择该项目有非全日制学生参加的院系"
			width="auto">
		</el-table-column>
	</el-table>
	</div>
</template>
<style scoped>
</style>
<script>
import axios from "axios";
//import qs from "qs";
export default {
	name: "PartTimeDept",
	data() {
		return {
			gameList: "",
			gameId: '',
			tableData: []
		};
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
		}
		axios({
			url: "/api/hello-user",
			method: "GET",
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		})
			.then((res) => {
				// get game list
				this.userid = res.data.userid;
				axios({
					url: "/api/admin/game/list",
					method: "GET",
					headers: {
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((res) => {
						this.gameList = res.data.data;
					})
					.catch((err) => {
						this.messageBox(
							"获取比赛列表失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
							"error"
						);
						console.log(err.response);
					});
			})
			.catch((err) => {
				this.messageBox(
					"获取身份信息失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
		
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
	},
	watch: {
		gameId(val) {
			axios({
					url: "/api/club/select/part-time?id=" + String(val),
					method: "GET",
					headers: {
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((res) => {
						this.tableData = res.data.data;
					})
					.catch((err) => {
						this.messageBox(
							"获取比赛列表失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
							"error"
						);
						console.log(err.response);
					});
		}
	}
};
</script>
